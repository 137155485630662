'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Cell, Icon, InputText, Spacer, Text } from '@vinted/web-ui'
import { useDispatch, useSelector } from 'react-redux'
import { Eye16, EyeDenied16 } from '@vinted/monochrome-icons'

import { FormattedMessage } from 'components/@support'
import FaqEntryUrl from 'components/FaqEntryUrl'
import SeparatedList from 'components/SeparatedList'
import useSuccessUrl from 'components/Auth/hooks/useSuccessUrl'

import { AuthenticateGrantType } from 'constants/auth'
import { FaqEntryType } from 'constants/faq-entry'
import { AccessChannel } from 'constants/index'
import { FORGOT_PASSWORD_URL, ROOT_URL } from 'constants/routes'
import { authenticateUser as authenticateUserNew } from 'data/api/authentication/requests'
import { authenticateUser } from 'data/api/account'
import { transformAuthenticateUserError } from 'data/transformers/authentication'

import { getFingerprint } from 'libs/utils/fingerprint'
import { navigateToPage } from 'libs/utils/window'
import { ResponseCode } from 'data/api/response-codes'
import useRefUrl from 'hooks/useRefUrl'
import useTranslate from 'hooks/useTranslate'
import { ExternalRegisterData } from 'state/auth/types'
import { renderValidation } from 'components/Input'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import { actions } from 'state/auth/slice'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import useDataDomeCaptcha from 'hooks/useDataDomeCaptcha'
import useAuthTracking from 'components/Auth/hooks/useAuthTracking'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = ExternalRegisterData

type Fields = {
  password: string
}

const PasswordVerificationLogin = ({ email, idToken }: Props) => {
  const translate = useTranslate('user.wrong_email_in_registration')
  const refUrl = useRefUrl(ROOT_URL)
  const successUrl = useSuccessUrl()
  const dispatch = useDispatch()
  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<Fields>()
  const getErrorMessage = useFormValidationMessage(errors)
  const isNewLoginEnabled = useSelector(getIsFeatureSwitchEnabled('next_auth_login'))
  const { trackInputEvent, trackClickEvent } = useAuthTracking()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const doAuthenticate = async ({ password }: Fields) => {
    if (!email) return

    const fingerprint = await getFingerprint()

    const authenticateHandler = isNewLoginEnabled ? authenticateUserNew : authenticateUser

    const response = await authenticateHandler({
      grantType: AuthenticateGrantType.Password,
      username: email,
      controlCode: idToken,
      password,
      fingerprint,
    })

    if (response.code === ResponseCode.Required2FA && 'payload' in response && response.payload) {
      dispatch(
        actions.openTwoFactorLoginView({
          authenticateUserError: transformAuthenticateUserError(response.payload),
          successUrl,
        }),
      )

      return
    }

    if ('errors' in response) {
      setError('password', { type: 'manual', message: response.message })

      return
    }

    navigateToPage(refUrl)
  }

  useDataDomeCaptcha(() => {
    handleSubmit(doAuthenticate)()
  })

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(prevState => !prevState)
  }

  const handleInputFocus = (target: string) => () => trackInputEvent({ target, state: 'focus' })

  const handleInputBlur = (target: string) => () => trackInputEvent({ target, state: 'unfocus' })

  const getInputEvents = (field: string) => {
    return {
      onFocus: handleInputFocus(field),
      onBlur: handleInputBlur(field),
    }
  }

  const handleClickTracking = (target: ClickableElement) => () => {
    trackClickEvent({ target })
  }

  const renderLink = ({
    url,
    text,
    eventTarget,
  }: {
    url: string
    text: string
    eventTarget: ClickableElement
  }) => (
    <a href={url} onClick={handleClickTracking(eventTarget)}>
      <Text bold text={text} clickable />
    </a>
  )

  const renderContactUsLink = (url: string) =>
    renderLink({ url, text: translate('contact_us'), eventTarget: ClickableElement.HavingTrouble })

  const renderPasswordResetLink = (url: string) =>
    renderLink({
      url,
      text: translate('reset_password'),
      eventTarget: ClickableElement.ForgotPassword,
    })

  const passwordIconName = isPasswordVisible ? EyeDenied16 : Eye16

  return (
    <form onSubmit={handleSubmit(doAuthenticate)}>
      <Cell>
        <Text
          text={translate('heading', { registration_email: email })}
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
          type={Text.Type.Heading}
        />
        <Spacer size={Spacer.Size.X2Large} />
        <Spacer />
        <FormattedMessage
          id="user.wrong_email_in_registration.error_explanation"
          values={{ registration_email: <Text text={email} bold /> }}
        />
      </Cell>
      <InputText
        {...register('password')}
        placeholder={translate('placeholder')}
        validation={renderValidation(getErrorMessage('password'))}
        type={isPasswordVisible ? InputText.Type.Text : InputText.Type.Password}
        suffix={
          <Button
            size={Button.Size.Medium}
            icon={<Icon name={passwordIconName} color={Icon.Color.GreyscaleLevel3} />}
            styling={Button.Styling.Flat}
            onClick={togglePasswordVisibility}
          />
        }
        {...getInputEvents('password')}
      />
      <Cell>
        <div className="u-text-center">
          <SeparatedList separator={<Spacer size={Spacer.Size.XLarge} />}>
            <Button
              testId="login-button"
              type={Button.Type.Submit}
              text={translate('button', { registration_email: email })}
              styling={Button.Styling.Filled}
              onClick={handleClickTracking(ClickableElement.SocialLoginWithPassword)}
            />
            {renderPasswordResetLink(FORGOT_PASSWORD_URL)}
            <FaqEntryUrl
              type={FaqEntryType.CantLogin}
              accessChannel={AccessChannel.ProductLink}
              render={url => renderContactUsLink(url)}
            />
          </SeparatedList>
        </div>
      </Cell>
      <Spacer />
    </form>
  )
}

export default PasswordVerificationLogin
