import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { AuthenticateGrantType, AuthenticateProvider } from 'constants/auth'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { authenticateUser as authenticateUserNew } from 'data/api/authentication/requests'
import { authenticateUser } from 'data/api/account'
import { getFingerprint } from 'libs/utils/fingerprint'
import useTranslate from 'hooks/useTranslate'

import { isBlockedByDataDome } from '../utils'

const useSocialLogin = () => {
  const translate = useTranslate('auth.select_type')
  const isNewLoginEnabled = useSelector(getIsFeatureSwitchEnabled('next_auth_login'))

  const loginWithSocial = useCallback(
    async (provider: AuthenticateProvider, token: string) => {
      const fingerprint = await getFingerprint()

      const authenticateHandler = isNewLoginEnabled ? authenticateUserNew : authenticateUser

      const response = await authenticateHandler({
        grantType: AuthenticateGrantType.Assertion,
        assertion: token,
        provider,
        fingerprint,
      })

      if ('errors' in response && isBlockedByDataDome(response)) {
        response.message = translate('errors.datadome')
      }

      return response
    },
    [translate, isNewLoginEnabled],
  )

  return loginWithSocial
}

export default useSocialLogin
