'use client'

import { Badge, Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

type Props = {
  login: string
  businessAccountName?: string
  isBusiness: boolean
  isBody?: boolean
  isLoginForced?: boolean
  isBottomLoginHidden?: boolean
  isBadgeHidden?: boolean
  truncate?: boolean
}

const UserTitle = ({
  login,
  businessAccountName,
  isBusiness,
  isBody,
  isLoginForced,
  isBottomLoginHidden,
  isBadgeHidden,
  truncate,
}: Props) => {
  const businessDisplayName = isBusiness && !isLoginForced && businessAccountName
  const title = businessDisplayName || login
  const translate = useTranslate('business')

  function renderBusinessAccountBadge() {
    if (!businessDisplayName || isBadgeHidden) return null

    return (
      <>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Regular} />
        <Badge content={translate('title')} testId="profile-pro-badge" />
      </>
    )
  }

  function renderBottomLogin() {
    if (!businessDisplayName || isBottomLoginHidden || isLoginForced) return null

    return (
      <Text
        width={Text.Width.Parent}
        text={`@${login}`}
        type={Text.Type.Subtitle}
        testId="profile-business-name"
      />
    )
  }

  return (
    <>
      <div className="u-flexbox u-align-items-center">
        <Text
          text={title}
          type={isBody ? Text.Type.Body : Text.Type.Heading}
          theme="amplified"
          truncate={truncate}
          bold
          testId="profile-username"
        />
        {renderBusinessAccountBadge()}
      </div>
      {renderBottomLogin()}
    </>
  )
}

export default UserTitle
