'use client'

import { Text } from '@vinted/web-ui'
import { useSelector } from 'react-redux'

import useTranslate from 'hooks/useTranslate'

import { registerAppleUser as registerAppleUserNew } from 'data/api/authentication/requests'
import { registerAppleUser } from 'data/api/account'
import { ExternalRegisterData } from 'state/auth/types'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getFingerprint } from 'libs/utils/fingerprint'

import RegisterForm, { FormData } from '../RegisterForm'
import { useRegistrationTracking } from '../hooks/useRegistrationTracking'

type Props = ExternalRegisterData

const AppleRegister = ({ idToken, realName, email }: Props) => {
  const translate = useTranslate()
  const trackRegistrationEvents = useRegistrationTracking()
  const isNewRegisterEnabled = useSelector(getIsFeatureSwitchEnabled('next_auth_register'))

  async function handleFormSubmit(data: FormData) {
    const fingerprint = await getFingerprint()

    const registerHandler = isNewRegisterEnabled ? registerAppleUserNew : registerAppleUser

    const response = await registerHandler({
      agreeRules: true,
      ...data,
      token: idToken,
      fingerprint,
    })

    await trackRegistrationEvents({
      response,
      authType: 'apple',
      email: data.email,
    })

    return response
  }

  return (
    <>
      <div className="u-ui-padding-horizontal-large">
        <Text
          id="auth_modal_title"
          text={translate('auth.external_register.title')}
          type={Text.Type.Heading}
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
        />
      </div>
      <RegisterForm email={email} realName={realName} onSubmit={handleFormSubmit} />
    </>
  )
}

export default AppleRegister
