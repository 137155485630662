'use client'

import { useSelector } from 'react-redux'
import { Divider, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import * as selectors from 'state/auth/selectors'

const BusinessRegistrationLink = () => {
  const isLoginView = useSelector(selectors.getIsSelectTypeLogin)
  const isBusinessRegistrationLinkVisible = useSelector(
    selectors.getIsBusinessRegistrationLinkVisible,
  )

  const translate = useTranslate()

  if (!isBusinessRegistrationLinkVisible || isLoginView) return null

  return (
    <>
      <Divider />
      <div className="u-ui-padding-horizontal-large u-ui-padding-vertical-x-large">
        <Text
          type={Text.Type.Caption}
          alignment={Text.Alignment.Center}
          text={translate('auth.select_type.register.are_you_a_business')}
          width={Text.Width.Parent}
          html
        />
      </div>
    </>
  )
}

export default BusinessRegistrationLink
