import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { GoogleTagManagerEvent, LoginStatus } from 'constants/google'
import { googleTagManagerTrack as track } from 'data/utils/google'
import useLocation from 'hooks/useLocation'
import { filterEmptyStringAttributes } from 'libs/utils/object'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getGoogleAnalyticsData } from 'state/selectors'
import usePageId from 'hooks/usePageId'

import { GoogleAnalyticsTrackProps } from './google/types'
import { getCurrentCatalogUrl, getFormName, getPageType } from './google/utils'

function useGoogleTagManagerTrack() {
  const { host, relativeUrl, searchParams } = useLocation()
  const data = useSelector(getGoogleAnalyticsData)
  const pageId = usePageId()

  const isGoogleAnalyticsTrackEnabled = useSelector(getIsFeatureSwitchEnabled('web_ga4_analytics'))
  const isGtmEcFieldEnabled = useSelector(getIsFeatureSwitchEnabled('web_gtm_ec_field'))

  const { anonymousId, language } = data
  const siteSearchString =
    typeof searchParams.search_text === 'string' ? searchParams.search_text : ''
  const pageCategory = getCurrentCatalogUrl(data.currentCatalogUrl)
  const userId = String(data.userId || '')
  const loginStatus = data.authStatus ? LoginStatus.Logged : LoginStatus.NotLogged

  const pageType = getPageType(relativeUrl, siteSearchString, pageCategory, pageId)

  const formName = getFormName(pageType)

  const googleTagManagerTrack = useCallback(
    (event: GoogleTagManagerEvent, trackingData: NonNullable<typeof window.dataLayer>[number]) =>
      track(event, trackingData, isGtmEcFieldEnabled),
    [isGtmEcFieldEnabled],
  )

  const googleAnalyticsTrack = useCallback(
    ({ event = GoogleTagManagerEvent.pageLoad, customTagObject }: GoogleAnalyticsTrackProps) => {
      const eventObject = filterEmptyStringAttributes({
        anonymousId,
        language,
        siteSearchString,
        loginStatus,
        userId,
        pageCategory,
        pageType,
        formName,
        ...customTagObject,
        domain: host,
      })

      if (isGoogleAnalyticsTrackEnabled) {
        track(event, eventObject)
      }
    },
    [
      anonymousId,
      formName,
      host,
      isGoogleAnalyticsTrackEnabled,
      language,
      loginStatus,
      pageCategory,
      pageType,
      siteSearchString,
      userId,
    ],
  )

  return { googleTagManagerTrack, googleAnalyticsTrack }
}

export default useGoogleTagManagerTrack
