'use client'

import { forwardRef, MouseEvent, Ref, useState } from 'react'
import { InputText, Icon } from '@vinted/web-ui'
import { Eye16, EyeDenied16 } from '@vinted/monochrome-icons'

type Props = Omit<ComponentProps<typeof InputText>, 'icon' | 'type'>

const PasswordField = forwardRef((props: Props, ref?: Ref<HTMLInputElement>) => {
  const [showPassword, setShowPassword] = useState(false)

  function handleToggleShowPassword(event: MouseEvent) {
    event.preventDefault()
    setShowPassword(prevState => !prevState)
  }

  return (
    <InputText
      {...props}
      ref={ref}
      type={showPassword ? InputText.Type.Text : InputText.Type.Password}
      suffix={
        <button
          type="button"
          onClick={handleToggleShowPassword}
          className="u-cursor-pointer"
          data-testid="auth-password-eye-icon"
          // TODO: Add aria-label
        >
          <Icon name={showPassword ? Eye16 : EyeDenied16} color={Icon.Color.GreyscaleLevel4} />
        </button>
      }
    />
  )
})

export default PasswordField
