import { useSelector } from 'react-redux'

import useRefUrl from 'hooks/useRefUrl'

import { BUSINESS_ACCOUNT_REGISTER_URL } from 'constants/routes'
import * as selectors from 'state/auth/selectors'

const useSuccessUrl = (defaultUrl?: string) => {
  const isBusinessAuth = useSelector(selectors.getIsBusinessAuth)
  const refUrl = useRefUrl(defaultUrl)

  if (isBusinessAuth) return BUSINESS_ACCOUNT_REGISTER_URL

  return refUrl
}

export default useSuccessUrl
