import { get } from 'lodash'

import { Response, ResponseError } from 'types/api'

/**
 * Checks if the response was rejected by our third-party bot protection DataDome.
 */
export const isBlockedByDataDome = (response: Response<unknown> | ResponseError<unknown>) => {
  if (!('errors' in response)) return false
  if (response.status !== 403) return false

  return !!get(response, 'exception.response.headers.x-dd-b')
}
