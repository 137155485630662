import useTracking from 'hooks/useTracking'
import useGoogleTagManagerTrack from 'hooks/useGoogleTagManagerTrack'
import {
  userRegisterFailEvent,
  userRegisterSuccessEvent,
  authenticateSuccessEvent,
} from 'libs/common/event-tracker/events'
import { RegisterUserResponse, Response, ResponseError } from 'types/api'
import { GoogleTagManagerEvent } from 'constants/google'

type RegistrationResponse = ResponseError<any> | Response<RegisterUserResponse>

type AuthType = Parameters<typeof userRegisterSuccessEvent>[0]['type']

type TrackRegistrationEventsArgs = {
  response: RegistrationResponse
  authType: AuthType
  email: string
}

const gtmTypeByAuthType: Record<AuthType, string> = {
  apple: 'apple',
  facebook: 'facebook',
  google: 'google',
  internal: 'email',
}

export const useRegistrationTracking = () => {
  const { track } = useTracking()
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()

  return ({ response, authType, email }: TrackRegistrationEventsArgs) => {
    const gtmAuthType = gtmTypeByAuthType[authType]

    if ('errors' in response) {
      track(userRegisterFailEvent({ type: authType, errors: response.errors }))

      return
    }

    googleTagManagerTrack(GoogleTagManagerEvent.Register, {
      auth_type: gtmAuthType,
      user_email: email,
    })
    track(userRegisterSuccessEvent({ type: authType, userId: response.user.id }))
    track(authenticateSuccessEvent({ type: authType, userId: response.user.id }))
  }
}
