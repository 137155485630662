import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadScript } from 'libs/utils/script'
import { getFacebookAppId } from 'state/next-env/selectors'
import { actions } from 'state/auth/slice'
import * as selectors from 'state/auth/selectors'

const FACEBOOK_SDK_LINK = 'https://connect.facebook.net/en_US/sdk.js'

const useInitFacebook = () => {
  const appId = useSelector(getFacebookAppId)
  const isInitialized = useSelector(selectors.getIsFacebookIsInitialized)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!appId) return
    if (isInitialized) return

    async function initialize() {
      await loadScript({
        id: 'facebook-sdk',
        isAsync: true,
        isDefer: true,
        crossOrigin: 'anonymous',
        src: FACEBOOK_SDK_LINK,
      })

      window.fbAsyncInit = () => {
        FB.init({
          appId,
          cookie: true,
          xfbml: true,
          version: 'v13.0',
        })

        dispatch(actions.setIsFacebookIsInitialized())
      }
    }

    initialize()
  }, [appId, dispatch, isInitialized])

  return {
    isInitialized,
    isEnabled: !!appId,
  }
}

export default useInitFacebook
