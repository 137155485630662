import { isEmpty } from 'lodash'
import { connect, ConnectedProps } from 'react-redux'
import { Image } from '@vinted/web-ui'

import { getSystemUserImage, getEmptyUserImage } from 'data/utils/user'

import { State as AppState } from 'state/types'
import { getPortal, getFlavour } from 'state/rails-context/selectors'

const mapStateToProps = (state: AppState) => ({
  portal: getPortal(state),
  flavour: getFlavour(state),
})

const connector = connect(mapStateToProps)

type OwnProps = {
  isSystem?: boolean
}

type Props = OwnProps & ComponentProps<typeof Image> & ConnectedProps<typeof connector>

const UserImage = (props: Props) => {
  const { src, isSystem, portal, flavour } = props

  const srcPath = isEmpty(src) ? getEmptyUserImage() : src
  const imgSrc = isSystem ? getSystemUserImage(portal, flavour) : srcPath

  return <Image {...props} src={imgSrc} alt={props.alt || ''} />
}

export { UserImage }

export default connector(UserImage)
