'use client'

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AuthFacebookButton } from 'components/Auth'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useGoogleTagManagerTrack from 'hooks/useGoogleTagManagerTrack'

import { getCurrentUser } from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import { transformAuthenticateUserError } from 'data/transformers/authentication'
import { AuthenticateProvider } from 'constants/auth'
import { GoogleTagManagerEvent } from 'constants/google'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { authenticateFailEvent, authenticateSuccessEvent } from 'libs/common/event-tracker/events'
import { navigateToPage } from 'libs/utils/window'
import { actions } from 'state/auth/slice'
import { AuthExternalRegisterView } from 'state/auth/constants'
import useDataDomeCaptcha from 'hooks/useDataDomeCaptcha'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import useSuccessUrl from '../hooks/useSuccessUrl'
import useSocialLogin from '../hooks/useSocialLogin'
import useAuthTracking from '../hooks/useAuthTracking'

type Props = {
  setError: (error?: string) => void
}

type SuccessFields = {
  email?: string
  birthday?: string
  gender?: string
  name?: string
  first_name?: string
  last_name?: string
}

const Facebook = ({ setError }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()
  const successUrl = useSuccessUrl()
  const authenticateSocial = useSocialLogin()
  const { trackClickEvent } = useAuthTracking()

  const [isLoading, setIsLoading] = useState(false)
  const [facebookToken, setFacebookToken] = useState('')
  const [facebookFields, setFacebookFields] = useState<SuccessFields>()

  const isFacebookRegistrationWithoutEmailEnabled = useSelector(
    getIsFeatureSwitchEnabled('facebook_registration_without_email'),
  )

  function handleClick() {
    trackClickEvent({ target: ClickableElement.LoginWithFacebook })
  }

  function trackError(error: string) {
    track(authenticateFailEvent({ type: 'facebook', error }))
  }

  function handleLoginWithoutEmail(controlCode: string, email?: string) {
    dispatch(
      actions.setExternalRegisterData({
        view: AuthExternalRegisterView.PasswordVerification,
        data: {
          idToken: controlCode,
          email,
        },
      }),
    )
  }

  async function handleSuccess(token: string, fields: SuccessFields) {
    setFacebookToken(token)
    setFacebookFields(fields)
    setIsLoading(true)

    const response = await authenticateSocial(AuthenticateProvider.Facebook, token)

    if ('errors' in response) {
      // TODO: handle ResponseCode.SessionFromTokenError
      if (
        response.code === ResponseCode.NotFound &&
        (isFacebookRegistrationWithoutEmailEnabled || fields.email)
      ) {
        const realName =
          fields.name ||
          [fields.first_name, fields.last_name].filter(Boolean).join(' ') ||
          undefined

        dispatch(
          actions.setExternalRegisterData({
            view: AuthExternalRegisterView.FacebookRegister,
            data: {
              idToken: token,
              realName,
              email: fields.email,
            },
          }),
        )
      } else if (
        response.code === ResponseCode.LoginWithoutEmail &&
        response.payload &&
        'control_code' in response.payload
      ) {
        handleLoginWithoutEmail(response.payload.control_code, fields.email)
      } else if (response.code === ResponseCode.Required2FA && response.payload) {
        dispatch(
          actions.openTwoFactorLoginView({
            authenticateUserError: transformAuthenticateUserError(response.payload),
            successUrl,
          }),
        )

        return
      } else {
        if (fields.email) {
          trackError(response.message)
        } else {
          trackError("FB API didn't return an email")
        }

        setIsLoading(false)
        setError(response.message)
      }

      return
    }

    const getCurrentUserResp = await getCurrentUser()
    const userId = 'errors' in getCurrentUserResp ? undefined : getCurrentUserResp.user.id

    googleTagManagerTrack(GoogleTagManagerEvent.Login, {
      auth_type: 'facebook',
      user_email: fields.email,
    })
    track(authenticateSuccessEvent({ type: 'facebook', userId }))
    navigateToPage(successUrl)
  }

  useDataDomeCaptcha(() => {
    if (!facebookToken || !facebookFields) return

    setError(undefined)
    handleSuccess(facebookToken, facebookFields)
  })

  function handleFailure() {
    trackError('User closed screen or did not provide permissions')
  }

  return (
    <AuthFacebookButton
      text={translate('auth.select_type.actions.facebook')}
      isLoading={isLoading}
      onClick={handleClick}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
    />
  )
}

export default Facebook
