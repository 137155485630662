'use client'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import ErrorState from 'components/ErrorState'
import { ExternalRegisterData } from 'state/auth/types'
import * as selectors from 'state/auth/selectors'
import { AuthExternalRegisterView, AuthView } from 'state/auth/constants'
import { isValueInObject } from 'libs/utils/object'
import PasswordVerification from 'pages/PasswordVerificationLogin'
import { LoginTwoFactorVerification } from 'pages/Verifications'

import SelectType from '../SelectType'
import EmailLogin from '../EmailLogin'
import EmailRegister from '../EmailRegister'
import ResetPassword from '../ResetPassword'
import FacebookRegister from '../FacebookRegister'
import GoogleRegister from '../GoogleRegister'
import AppleRegister from '../AppleRegister'
import useAuthTracking from '../hooks/useAuthTracking/useAuthTracking'

const componentByAuthView: Record<AuthView, () => JSX.Element | null> = {
  [AuthView.SelectTypeRegister]: SelectType,
  [AuthView.SelectTypeLogin]: SelectType,
  [AuthView.EmailLogin]: EmailLogin,
  [AuthView.EmailRegister]: EmailRegister,
  [AuthView.ResetPassword]: ResetPassword,
  [AuthView.TwoFactorLogin]: LoginTwoFactorVerification,
}

const componentByAuthExternalRegisterView: Record<
  AuthExternalRegisterView,
  (args: ExternalRegisterData) => JSX.Element
> = {
  [AuthExternalRegisterView.FacebookRegister]: FacebookRegister,
  [AuthExternalRegisterView.GoogleRegister]: GoogleRegister,
  [AuthExternalRegisterView.AppleRegister]: AppleRegister,
  [AuthExternalRegisterView.PasswordVerification]: PasswordVerification,
}

const Auth = () => {
  const view = useSelector(selectors.getAuthView)
  const externalRegisterData = useSelector(selectors.getExternalRegisterData)
  const { trackViewScreen } = useAuthTracking()

  useEffect(() => {
    trackViewScreen()
  }, [trackViewScreen])

  if (isValueInObject(view, AuthView)) {
    const Component = componentByAuthView[view]

    return <Component />
  }

  if (!externalRegisterData) return <ErrorState />

  const Component = componentByAuthExternalRegisterView[view]

  return <Component {...externalRegisterData} />
}

export default Auth
