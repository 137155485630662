'use client'

import { useDispatch, useSelector } from 'react-redux'
import { Spacer, Text } from '@vinted/web-ui'

import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'

import { REGISTER_URL } from 'constants/routes'
import { onA11yKeyDown } from 'data/utils/a11y'
import { getOldHostRedirectUrl } from 'libs/utils/url'
import { navigateToPage } from 'libs/utils/window'

import { AuthView } from 'state/auth/constants'
import { getIsSelectTypeLogin } from 'state/auth/selectors'
import { actions } from 'state/auth/slice'
import useAuthTracking from 'components/Auth/hooks/useAuthTracking'
import { ClickableElement } from 'constants/tracking/clickable-elements'

const SelectTypeFooter = () => {
  const isLoginView = useSelector(getIsSelectTypeLogin)

  const dispatch = useDispatch()
  const selectType = isLoginView ? 'login' : 'register'
  const translate = useTranslate(`auth.select_type.${selectType}`)
  const { host } = useLocation()
  const { trackClickEvent } = useAuthTracking()

  function handleSwitchContext() {
    if (isLoginView) {
      dispatch(actions.setAuthView({ authView: AuthView.SelectTypeRegister }))
      trackClickEvent({ target: ClickableElement.SwitchToRegistration })

      return
    }

    dispatch(actions.setAuthView({ authView: AuthView.SelectTypeLogin }))
    trackClickEvent({ target: ClickableElement.SwitchToLogin })
  }

  function proceedToEmailLogin() {
    dispatch(actions.setAuthView({ authView: AuthView.EmailLogin }))
    trackClickEvent({ target: ClickableElement.SwitchToEmailLogin })
  }

  function proceedToEmailRegister() {
    const hostRedirectUrl = getOldHostRedirectUrl(host)
    if (hostRedirectUrl) {
      navigateToPage(`${hostRedirectUrl}${REGISTER_URL}`)

      return
    }

    dispatch(actions.setAuthView({ authView: AuthView.EmailRegister }))
    trackClickEvent({ target: ClickableElement.SwitchToEmailRegistration })
  }

  function handleProceedLinkClick() {
    if (isLoginView) {
      proceedToEmailLogin()
    } else {
      proceedToEmailRegister()
    }
  }

  return (
    <>
      <Text width={Text.Width.Parent} alignment={Text.Alignment.Center}>
        {translate('actions.proceed.title')}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <span
          role="button"
          onKeyDown={onA11yKeyDown}
          className="u-cursor-pointer"
          tabIndex={0}
          onClick={handleProceedLinkClick}
          data-testid={`auth-select-type--${selectType}-email`}
        >
          <Text text={translate('actions.proceed.link')} theme="primary" />
        </span>
      </Text>
      <Spacer />
      <Text width={Text.Width.Parent} alignment={Text.Alignment.Center}>
        {translate('actions.switch.title')}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <span
          role="button"
          onClick={handleSwitchContext}
          onKeyDown={onA11yKeyDown}
          className="u-cursor-pointer"
          tabIndex={0}
          data-testid={`auth-select-type--${selectType}-switch`}
        >
          <Text text={translate('actions.switch.link')} theme="primary" />
        </span>
      </Text>
    </>
  )
}

export default SelectTypeFooter
