'use client'

import { Spacer, Cell, Text, Button, Card, Divider, Icon } from '@vinted/web-ui'
import { Dropdown16 } from '@vinted/monochrome-icons'

import OutsideClick from 'components/OutsideClick'
import SeparatedList from 'components/SeparatedList'
import { LanguageDto } from 'types/dtos'

import useLanguageSelector from '../useLanguageSelector'

type Props = {
  languages: Array<LanguageDto>
  activeLanguage: LanguageDto
  setActiveLanguage: (language: LanguageDto) => void
}

const LanguageSelectorDropdown = ({ languages, activeLanguage, setActiveLanguage }: Props) => {
  const { isMenuOpen, toggleMenu, hideMenu, saveLanguage } = useLanguageSelector({
    activeLanguage,
    setActiveLanguage,
  })

  const { title_short: titleShort } = activeLanguage
  const handleLanguageSelection = (language: LanguageDto) => () => saveLanguage(language)

  const renderLanguageText = (language: LanguageDto) => {
    const { id: activeLanguageId } = activeLanguage

    const activeLanguageProps: Partial<ComponentProps<typeof Text>> = {
      theme: 'amplified' as const,
      bold: true,
    }

    const props = language.id === activeLanguageId ? activeLanguageProps : {}

    return (
      <>
        <Text text={language.title_local} {...props} />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <Text text={`(${language.title})`} {...props} />
      </>
    )
  }

  return (
    <OutsideClick onOutsideClick={hideMenu}>
      <div className="u-ui-padding-left-small">
        <Button
          text={titleShort.toUpperCase()}
          styling={Button.Styling.Flat}
          size={Button.Size.Medium}
          theme="muted"
          inline
          truncated={false}
          icon={<Icon name={Dropdown16} color={Icon.Color.GreyscaleLevel3} />}
          iconPosition={Button.IconPosition.Right}
          onClick={toggleMenu}
          aria={{
            'aria-expanded': isMenuOpen,
          }}
        />
        {isMenuOpen && (
          <div className="language-selector__dropdown">
            <Card styling={Card.Styling.Elevated}>
              <div className="u-overflow-hidden">
                <SeparatedList separator={<Divider />}>
                  {languages.map(language => (
                    <Cell
                      type={Cell.Type.Navigating}
                      onClick={handleLanguageSelection(language)}
                      key={language.id}
                    >
                      {renderLanguageText(language)}
                    </Cell>
                  ))}
                </SeparatedList>
              </div>
            </Card>
          </div>
        )}
      </div>
    </OutsideClick>
  )
}

export default LanguageSelectorDropdown
